import MyGender from '@/modules/questionnaire/components/steps/common/identity/demographics/MyGender';

export default {
  title: 'Steps/Identity/Demographics/components/MyGender',
  component: MyGender
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { MyGender },
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  data() {
    return { gender: '' };
  },
  template: '<div :style="$options.wrapStyles"><my-gender v-bind="$props" v-model="gender" /></div>'
});

export const Base = Template.bind({});
Base.args = {};
